import React from 'react'
import { Link } from 'gatsby'
import { ElfsightWidget } from 'react-elfsight-widget';

// Context
import ConfigContext from '../../context/ConfigProvider.js';

// Components
import Delimiter from "../content/delimiter.js"

// Functions
import gateway from '../../fetch/gateway.js';
import { dataLayerPush, storeUserInformation } from "../../functions/data-layer.js"

// Content
import USP from '../content/usps.js';

// Images
import LogoSVG from "../../images/attitude-logo.svg"

// Icons
import Facebook from "../../icons/facebook.js"
import Instagram from "../../icons/instagram.js"


import "../../styles/footer.scss"

const Footer = () => {
    const { configData } = React.useContext(ConfigContext);
    const [newsletterIndex, setNewsletterIndex] = React.useState(0)
    const [email, setEmail] = React.useState(false)
    const [phone, setPhone] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const toggleNewsletterIndex = (e, type) => {
        e.preventDefault();
        if (type === "up") setNewsletterIndex(newsletterIndex + 1)
        if (type === "down") setNewsletterIndex(newsletterIndex - 1)
        // Fields
        if (e?.target?.email?.value) setEmail(e.target.email.value)
        if (e?.target?.phone?.value) setPhone(e.target.phone.value)
    }
    const sendNewsletterSignup = async (e) => {
        e.preventDefault();
        if (email) await gateway({ type: "newsletter_signup", email: email, phone: phone })
            .then(res => {
                if (res.signup) {
                    setSuccess(true)
                    storeUserInformation("set", "user-id", res.user_id)
                    storeUserInformation("set", "email", email)
                    storeUserInformation("set", "phone", phone)
                    dataLayerPush("newsletter_signup", {
                        customer: {
                            user_id: res.user_id,
                            email: email,
                            phone: phone
                        }
                    })
                }
                else {
                    setSuccess(res.message)
                    setTimeout(() => {
                        setSuccess(false)
                    }, 15000);
                }
            })
            .catch(err => {
                setSuccess(err)
                setTimeout(() => {
                    setSuccess(false)
                }, 15000);
            })
    }

    return (
        <footer id="footer">
            <div className="newsletterSign">
                <div className="newsletterSignWrapper">
                    {!success ? <><div className="text">
                        <p><b>Skriv upp dig på vårt nyhetsbrev — Få 5% rabatt på din första order</b></p>
                        <p>Dessutom får du erbjudanden och nyheter direkt i din inkorg.</p>
                    </div>
                        <div className="form">
                            {newsletterIndex === 0 ? <form onSubmit={(e) => toggleNewsletterIndex(e, "up")}>
                                <label htmlFor="email">Epost</label>
                                <input id="email" name="email" type="email" placeholder="Lägg till din epost" required />
                                <button type="submit">Skriv upp mig</button>
                            </form> : null}
                            {newsletterIndex === 1 ? <form onSubmit={(e) => toggleNewsletterIndex(e, "up")}>
                                <label htmlFor="phone">Telefon</label>
                                <input id="phone" name="phone" type="tel" placeholder="Lägg till ditt telefonnummer (frivilligt)" />
                                <button type="submit">Skriv upp mig</button>
                            </form> : null}
                            {newsletterIndex === 2 ? <form onSubmit={(e) => sendNewsletterSignup(e)}>
                                <label htmlFor="gdpr">Acceptera GDPR & Privacypolicy</label>
                                <p>Kryssa i rutan för att godkänna att vi hanterar dina personuppgifter i enlighet med GDPR och vår integritetspolicy.</p>
                                <input id="gdpr" type="checkbox" required />
                                <button type="submit">Skriv upp mig</button>
                            </form> : null}
                        </div></> : success?.length > 0 ? <div className="text success">
                            <h3>Något gick fel!</h3>
                            <p>{success}</p>
                        </div> : <div className="text success">
                        <h3>Du är nu uppsignad på nyhetsbrevet</h3>
                        <p>Din rabatt på 5% läggs automatiskt till på din nästa order.</p>
                    </div>}
                </div>
            </div>
            <div className="footerWrapper">
                <div className="boxWrapper">
                    <div className="box center company">
                        <div className="logo">
                            <img src={LogoSVG} alt="Attitude.se Logo"/>
                        </div>
                        <address className="companyText">
                            <p><b>Julian Trycker AB</b></p>
                            <p><a href="https://www.google.com/maps/place/Attitude.se+%E2%80%94+Tryckeri/@57.7032894,11.9542684,17z/data=!3m1!4b1!4m6!3m5!1s0x464ff368116e3b33:0x12d153cdd7154f3d!8m2!3d57.7032894!4d11.9568433!16s%2Fg%2F1yh9tqb2s?entry=ttu" target="_blank" rel="noopener">Ingenjörsgatan 5</a></p>
                            <p>411 19 Göteborg</p>
                            <p>Org-nr: 556780-4413</p>
                            <p>Tel: <a href="tel:+4631134141">031 - 13 41 41</a></p>
                            <br /><br />
                            <p><b>Attitude.se</b> har hjälpt företag och privatpersoner med tryck på allt sedan <b>2003</b>.</p>
                        </address>
                    </div>
                    <nav className="box">
                        <ul>
                            <li className="main">Kontakt</li>
                            <li><Link to="/kontakta-oss" title={"Kontakta oss för tryck"}>Kontaktformulär</Link></li>
                            <li><a href="tel:031134141" rel="noopener">Telefon</a></li>
                            <li><Link to="#">Showroom</Link></li>
                        </ul>
                        <ul>
                            <li className="main">Köpinformation</li>
                            <li><Link to="/kopvillkor/" title={"Gå till Köpvillkor"}>Köpvillkor</Link></li>
                            <li><Link to="/vara-garantier/" title={"Gå till Våra Garantier"}>Våra garantier</Link></li>
                            <li><Link to="/frakt-och-retur/" title={"Gå till Frakt och Retur"}>Frakt & retur</Link></li>
                            {/* <li><Link to="/fragar-och-svar/">Frågor & svar</Link></li> */}
                            <li><Link to="/etisk-produktion/" title={"Gå till Etisk Produktion"}>Etisk produktion</Link></li>
                        </ul>
                    </nav>
                    <nav className="box">
                        <ul>
                            <li className="main">Tryck på</li>
                            <li><Link to="/klader/" title={"Gå till Tryck på Kläder"}>Kläder</Link></li>
                            <li><Link to="/ytterklader/" title={"Gå till Tryck på Ytterkläder"}>Ytterkläder</Link></li>
                            <li><Link to="/kontor/" title={"Gå till Tryck på Kontorsmaterial"}>Kontorsmaterial</Link></li>
                            <li><Link to="/give-aways/" title={"Gå till Tryck på Give-aways"}>Give-aways</Link></li>
                            <li><Link to="/vaskor-pasar/" title={"Gå till Tryck på Väskor & Påsar"}>Väskor & påsar</Link></li>
                            <li><Link to="/tryck-pa-eget-plagg/" title={"Gå till Tryck på Egna Plagg"}>Egna plagg</Link></li>
                        </ul>
                        <ul>
                            <li className="main">Tryckinformation</li>
                            <li><Link to="/mangdrabatter/" title={"Läs mer om Mängdrabatter"}>Mängdrabatter</Link></li>
                            <li><Link to="/tryckteknik/" title={"Läs mer om Trycktekniker"}>Trycktekniker</Link></li>
                        </ul>
                    </nav>
                    <nav className="box">
                        <ul>
                            <li className="main">Attitude.se</li>
                            <li><Link to="/om-attitude/" title={"Läs mer om Attitude.se"}>Om Attitude.se</Link></li>
                            <li><Link to="/referenskunder/" title={"Läs mer om våra referenser"}>Referenser</Link></li>
                            <li><Link to="/sponsring/" title={"Läs mer om Sponsring"}>Sponsring</Link></li>
                            <li><Link to="/tvattrad-och-skotsel" title={"Läs mer om Tvättråd och Skötsel"}>Tvättråd & skötsel</Link></li>
                            <li><Link to="/kontakta-oss" title={"Gå till Kontakta oss"}>Kontakta oss</Link></li>
                            <li><Link to="/guide/basta-t-shirt-for-tryck/" title={"Gå till Guide: Välj den bästa T-shirten för Tryck 2024"}>Guide: Bästa T-shirt för Tryck</Link></li>
                            <li><Link to="/guide/hitta-den-basta-hoodien-for-tryck/" title={"Gå till Guide: Hitta den bästa hoodien för Tryck 2024"}>Guide: Bästa Hoodie för Tryck</Link></li>
                        </ul>
                        <ul>
                            <li className="main">Följ oss</li>
                            <li>
                                <a href="https://www.facebook.com/attitude.se/" target="_blank" rel="noopener"><Facebook color={"var(--attitude-yellow)"} /></a>
                                <a href="https://www.instagram.com/attitude.se/" target="_blank" rel="noopener"><Instagram color={"var(--attitude-yellow)"} /></a>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/* <div className="boxWrapper">
                    <div className="box delimiter">
                        <p>Kategorier att trycka på</p>
                    </div>
                </div>
                <div className="boxWrapper navigation">
                    <nav className="box">
                        <ul>
                            {configData.categoryNav.map(obj => (<li key={obj.bigcommerce_id} className="main">
                                <Link to={obj.custom_url.url} title={"Gå till " + obj.name}>{obj.name}</Link>
                            </li>))}
                        </ul>
                    </nav>
                </div> */}
                <USP />
                <Delimiter text={"Vad våra kunder säger"} />
                <ElfsightWidget widgetId={"17e2ef9a-b791-4940-99a1-bec61a6de388"} lazy="in-viewport" modern />
                <div className="boxWrapper noMargin">
                    <div className="box copyright center">
                        <p>2003 - {new Date().getFullYear()} © Attitude.se skapad av Julian Trycker AB · <Link to="/privacy-policy/" title="Gå till vår Privacy Policy">Privacy Policy</Link> · <Link to="/cookie-policy/" title="Gå till vår Cookie Policy">Cookie Policy</Link></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
